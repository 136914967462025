<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div class="uk-margin">
      <div uk-grid class="uk-grid-small">
        <div class="uk-inline">
          <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
          <input class="uk-input" @keyup.enter="(e) => changeMeta(e, 'name')" type="text" placeholder="Search role name">
        </div>
         <div class="uk-form-controls uk-width-small">
          <select class="uk-select" id="form-horizontal-select" v-model="meta.status" @change="(e) => changeMeta(e, 'status')">
            <option :value=null>Select status</option>
            <option :value=1>Active</option>
            <option :value=0>Non Active</option>
          </select>
        </div>
        <div class="uk-inline">
          <button style="width:100px; cursor:pointer; height:40px" class="uk-button-primary" @click="showModal">Create</button>
        </div>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin">
      <div class="uk-overflow-auto">
        <table class="uk-table uk-table-small uk-table-divider uk-table-striped">
          <thead>
            <tr>
              <th class="uk-text-center">Company Name</th>
              <th class="uk-text-center">Role Name</th>
              <th class="uk-text-center">Status</th>
              <th class="uk-text-center uk-width-1-4">Action</th>
            </tr>
          </thead>
          <tbody v-if="company_roles.docs.length>0" class="uk-table-middle uk-text-center ">
            <tr v-for="(role, i) in company_roles.docs" :key="i">
              <td>{{role.company.name}}</td>
              <td>{{role.name}}</td>
              <td>
                <div class="label uk-margin-auto" :style="`background-color: ${role.status ? '#00cc00' : '#e63c3c'}`">
                  {{role.status ? 'Active' : 'Non Active'}}
                </div>
              </td>
              <td>
                <button
                  style="width:100px; cursor:pointer; margin-bottom:10px; height:30px; background:#333747"
                  class="uk-button-primary"
                  v-clipboard:copy="role._id"
                  v-clipboard:success="onCopy">
                  Copy Id
                </button><br/>
                <button
                  style="width:100px; cursor:pointer; height:30px"
                  class="uk-button-default uk-margin-right"
                  @click="editCompanyRole(role)">
                  Edit
                </button>
                <button
                  v-if="isLoading"
                  style="width:100px; cursor:pointer; height:30px"
                  class="uk-button-danger" type="button" disabled>
                  <div uk-spinner></div>
                </button>
                <button
                  v-else
                  style="width:100px; cursor:pointer; height:30px"
                  class="uk-button-danger"
                  @click="showDeleteModal(role)">
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
          <empty-table v-else :colspan="6" />
        </table>
      </div>
      <pagination
        :total-data="company_roles.totalDocs"
        :change-limit="changeLimit"
        :change-page="changePage"
      />
    </div>

    <div id="formRoleModal" uk-modal esc-close="false" bg-close="false">
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close @click="hideModal"></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title">Company Role Form</h2>
        </div>
        <div class="uk-modal-body">
          <div class="uk-margin">
            <label class="uk-form-label">Company <b class="uk-text-danger">*</b></label>
            <multiselect
              v-model="companyRoleForm.company_id"
              placeholder="Type to search company"
              label="name"
              name="company_id"
              track-by="name"
              v-validate="'required'"
              :options="company_options"
              :searchable="true"
              :loading='isFetching'
              :internal-search="false"
              :options-limit="50"
              @search-change="setCompanyValues"
              >
            </multiselect>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('company_id')">{{ errors.first('company_id') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Role Name <b class="uk-text-danger">*</b></label>
            <input
              class="uk-input"
              :class="{'uk-form-danger': errors.has('role_name')}"
              name="role_name"
              type="text"
              v-model="companyRoleForm.name"
              v-validate="'required'"
              placeholder="Warehouse Manager">
            <span class="uk-text-small uk-text-danger" v-show="errors.has('role_name')">{{ errors.first('role_name') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Role Status <b class="uk-text-danger">*</b></label>
            <multiselect
              v-model="companyRoleForm.status"
              placeholder="Select Status"
              label="name"
              name="role_status"
              track-by="name"
              v-validate="'required'"
              :options="status_options">
            </multiselect>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('role_status')">{{ errors.first('role_status') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">
              <input
                class="uk-checkbox uk-margin-small-right"
                name="internal_shipper"
                type="checkbox"
                v-model="companyRoleForm.internal_shipper"
              >
                Internal Role
            </label>
          </div>

        </div>
        <div class="uk-modal-footer uk-text-right">
          <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
          <button v-else class="uk-button uk-button-primary" type="button" @click="saveCompanyRole">Save</button>
        </div>
      </div>
    </div>

    <div id="deleteRoleModal" uk-modal esc-close="false" bg-close="false">
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close @click="hideDeleteModal"></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title">Delete Company Role?</h2>
        </div>
        <div class="uk-modal-body">
          <div class="uk-margin">
            <div>
              <h4>Company Name:</h4>
              <p>{{companyRoleForm.company_id.name}}</p>
            </div>
            <div>
              <h4>Role Name:</h4>
              <p>{{companyRoleForm.name}}</p>
            </div>
            <div>
              <h4>Status:</h4>
              <p>{{companyRoleForm.status.name}}</p>
            </div>
            <div>
              <h4>Internal Role:</h4>
              <p>{{companyRoleForm.internal_shipper ? "Yes" : "No"}}</p>
            </div>
          </div>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
          <button v-else class="uk-button uk-button-danger" type="button" @click="markRoleDeleted">Delete</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Pagination from '@/components/globals/Pagination';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import { mapActions, mapGetters } from 'vuex';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';

export default {
    data() {
        return {
            meta: {
                limit: 100,
                page: 1,
                name: '',
                status: null,
                is_deleted: false,
            },
            companyRoleForm: {
                company_id: '',
                name: '',
                status: '',
                internal_shipper: false,
            },
            formIsEdit: false,
            isLoading: false,
            status_options: [
                { name: 'Active', value: 1},
                { name: 'Non Active', value: 0}
            ],
            company_options: [],
            isFetching: false,
        };
    },
    components: {
        Pagination,
        EmptyTable
    },
    computed: {
        ...mapGetters({
            company_roles: 'company_role/company_roles',
            companies: 'company/companies',
        })
    },
    watch: {
        meta() {
            this.setCompanyRoleMeta();
        },
    },
    mounted() {
        this.setCompanyRoleMeta();
    },
    methods: {
        ...mapActions({
            getCompanyRoles: 'company_role/getCompanyRoles',
            createCompanyRole: 'company_role/createCompanyRole',
            updateCompanyRole: 'company_role/updateCompanyRole',
            deleteCompanyRole: 'company_role/deleteCompanyRole',
            getCompanies: 'company/getCompanies',
        }),
        setCompanyRoleMeta() {
            this.getCompanyRoles(this.meta);
        },
        async setCompanyValues(query) {
            this.isFetching = true;

            await this.getCompanies({name: query});
            this.company_options = this.companies.docs.map(
                obj => ({name: obj.name, value: obj._id})
            );

            this.isFetching = false;
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
            this.getCompanyRoles(this.meta);
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        async saveCompanyRole() {
            try {
                const validate = await this.$validator.validateAll();
                if (!validate || this.$validator.errors.any()) return;

                this.isLoading = true;

                this.companyRoleForm.company_id = this.companyRoleForm.company_id.value;

                this.companyRoleForm.status = this.companyRoleForm.status.value;

                const response = this.formIsEdit
                    ? await this.updateCompanyRole(this.companyRoleForm)
                    : await this.createCompanyRole(this.companyRoleForm);

                this.isLoading = false;
                if (response && response.status === 'OK') {
                    notificationSuccess('Company role saved!');
                    this.hideModal();
                    this.setCompanyRoleMeta();
                } else {
                    notificationDanger('Failed to save company role.');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        fillForm(role) {
            try {
                this.companyRoleForm.id = role._id;
                this.companyRoleForm.name = role.name;
                this.companyRoleForm.internal_shipper = role.internal_shipper;
                this.companyRoleForm.status = this.status_options.find(
                    e => e.value === role.status
                );
                this.companyRoleForm.company_id = {name: role.company.name, value: role.company._id};
            } catch (err) {
                notificationDanger(err);
            }
        },
        editCompanyRole(role) {
            this.formIsEdit = true;
            this.fillForm(role);
            this.showModal();
        },
        async markRoleDeleted() {
            try {
                this.isLoading = true;
                const response = await this.deleteCompanyRole(this.companyRoleForm.id);
                this.isLoading = false;

                if (response && response.status === 'OK') {
                    notificationSuccess('Company role deleted!');
                    this.hideDeleteModal();
                    this.setCompanyRoleMeta();
                } else {
                    notificationDanger('Failed to delete company role.');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        resetForm() {
            this.formIsEdit = false;
            this.companyRoleForm = {
                company_id: '',
                name: '',
                status: '',
                internal_shipper: false,
            };
            this.$validator.reset();
        },
        async showDeleteModal(role) {
            this.fillForm(role);
            await window.UIkit.modal('#deleteRoleModal').show();
        },
        async hideDeleteModal() {
            await window.UIkit.modal('#deleteRoleModal').hide();
            this.resetForm();
        },
        async showModal() {
            await window.UIkit.modal('#formRoleModal').show();
        },
        async hideModal() {
            await window.UIkit.modal('#formRoleModal').hide();
            this.resetForm();
        },
        onCopy(e) {
            notificationSuccess('Copied to clipboard: ' + e.text);
        },
    }
};
</script>
